import React from "react";
import {Link} from "gatsby"

import "./menu.scss"

const Menu = (props) => (
    <nav className="menu">
        <ul>
            <li><Link to={`/`}>{props.leftTitle}</Link></li>
            <li><Link to={`/about`}>über mich</Link></li>
        </ul>
    </nav>
);

export default Menu;