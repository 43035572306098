import React from "react";

import "./contour-header.scss";
import Arrow from "../arrow/Arrow";

const ContourHeader = (props) => (
    <div>
        <header className={`${props.ort} hero-image`}>
            <div className="header-text container">
                {props.ort === 'zurich' ?
                <span>Hallo,</span> : ''}
                <span>{props.text}&nbsp;
                    <span className={`location-highlight-${props.ort}`}>{props.destination}</span>.
                </span>
            </div>
            <div className="arrow">
                <a href={props.arrowRef}>
                    <span>{props.arrowText}</span>
                    <div className="arrow-icon">
                        <Arrow color={props.arrowColor}/>
                    </div>
                </a>
            </div>
        </header>
    </div>
);

export default ContourHeader